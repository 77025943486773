
<template>
    <div>
        <el-row>
            <el-col>
                <span style="font-size:18px;">{{URLinfo}}</span>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <span style="font-size:18px;">{{loadId}}</span>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <span style="font-size:18px;">{{equipmentInfo}}</span>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <span style="font-size:18px;">{{os}}</span>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <span style="font-size:18px;">{{brower}}</span>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <span style="font-size:18px;">{{ip}}</span>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <span style="font-size:18px;">{{area}}</span>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { add, Info } from "@/api/QRcode";
    import * as sysTool from "@/util/systemTool";

    export default {
        data() {
            return {
                loadId: '',
                URLinfo: '',
                brower: '',
                os: '',
                ip: '',
                area: '',
                equipmentInfo: '',

            }
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                this.loadId = this.$route.query.ID;
                this.qrInfo();
            },
            async qrInfo() {
                await Info(this.loadId).then(res => {
                    var o = res.data;
                    this.URLinfo = o.url;
                    this.addQRCodeLog();//���ɶ�ά��
                })
            },
            addQRCodeLog() {
                this.ip = returnCitySN["cip"];
                this.area = returnCitySN["cname"];
                this.brower = sysTool.GetCurrentBrowser();
                this.os = sysTool.GetOs();
                let row = [
                    this.brower,
                    this.os,
                    this.area,
                ];
                this.equipmentInfo = row.toString();
                let paramrow = {
                    QRCodeId: this.loadId,
                    IP: this.ip,
                    Device: this.equipmentInfo,
                };
                add(paramrow).then(() => {
                    window.location.href = this.URLinfo;
                }
                ).catch((erro) => { console.log(erro) });

            },
        }
    }
</script>