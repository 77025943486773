var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c("el-col", [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _vm._v(_vm._s(_vm.URLinfo))
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _vm._v(_vm._s(_vm.loadId))
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _vm._v(_vm._s(_vm.equipmentInfo))
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _vm._v(_vm._s(_vm.os))
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _vm._v(_vm._s(_vm.brower))
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _vm._v(_vm._s(_vm.ip))
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", [
            _c("span", { staticStyle: { "font-size": "18px" } }, [
              _vm._v(_vm._s(_vm.area))
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }