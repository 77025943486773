import request from '@/router/axios';
import { pvhApiBase } from "@/config/env";

//��ѯ
export const recodeList = (qrCodeId, iP, device, beginTime, endTime, page, pageSize) => {
    return request({
        url: pvhApiBase + "Tracing/GetQRCodeRecodes",
        method: "get",
        params: {
            "qrCodeId": qrCodeId,
            "iP": iP,
            "device": device,
            "beginTime": beginTime,
            "endTime": endTime,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//����������¼
export const add = (row) => {
    return request({
        url: pvhApiBase + "Tracing/PostQRCodeRecode",
        method: "post",
        data: {
            ...row
        }
    })
}

//��ȡ����
export const Info = (id) => {
    return request({
        url: pvhApiBase + "Tracing/GetQRCode",
        method: "get",
        params: {
            "id": id
        }
    })
}

